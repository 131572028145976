<template>
  <div class="content-wrapper">
    <page-header
        screen-name="Cadastrar Novo"
        :link-items="linkItems"
    />

    <Form
        mode="insert"
        :form-data="form"
        @clear="clearForm"
    />
  </div>
</template>

<script>

import PageHeader from '@/views/components/custom/page-header/PageHeader';
import Form from './Form.vue'

export default {
  components: {
    PageHeader,
    Form,
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Usuários Departamento Api',
          routeName: '',
        },
        {
          name: 'Cadastrar Novo',
          active: true,
        },
      ],

      form: {
        department: '',
        departmentType: null,
        endpoints: []
      },
    }
  },

  methods: {
    clearForm() {
      this.form = {
        department: '',
        departmentType: {
          boolValue: false,
          description: 'Não',
        },
        endpoints: []
      }
    },
  },
}
</script>
